import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import flatten from 'flat';
import moment from 'moment';
import { Routes, Route, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useSelector } from 'react-redux';
import 'moment/locale/vi';

import THEME from 'config/themes';
import AppLocale from 'config/translations';
import { validateMessages } from 'constants/common';
import { ROLE } from 'constants/roles';
import { PATH_NAME } from 'constants/routes';
import GlobalStyle from 'styles/globalStyle';
import { PrivateRoute } from 'components';
import DashboardLayout from './layout/DashboardLayout';
import AuthLayout from './layout/AuthLayout';
import Login from './views/auth/Login';
import Users from './views/users/Users';
import { NotFound } from './components/layout/NotFound';
import { Permission } from 'components/permission/Permission';
import Stores from './views/stores/Stores';
import ViewStore from './views/stores/ViewStore';
import RequestPermissionExport from './views/exports/RequestPermissionExport';
import ViewMap from './views/dashboard/ViewMap';
import StoreTypes from './views/store-types/StoreTypes';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  const locale = useSelector((state) => state.app.currentLocale);

  moment.locale(locale);

  return (
    <ConfigProvider
      locale={AppLocale[locale].antd}
      form={{ validateMessages: validateMessages[locale], requiredMark: false }}
      // componentSize="small"
    >
      <IntlProvider
        locale={locale}
        messages={flatten(AppLocale[locale].messages)}
      >
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={THEME}>
            <GlobalStyle />

            <Routes>
              <Route element={<PrivateRoute />}>
                <Route path={PATH_NAME.ROOT} element={<DashboardLayout />}>
                  <Route
                    index
                    element={
                      <Navigate
                        to={`${PATH_NAME.DASHBOARD}/${PATH_NAME.DASHBOARD_MAP}`}
                      />
                    }
                  />

                  <Route path={PATH_NAME.USERS}>
                    <Route
                      index
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <Users />
                        </Permission>
                      }
                    />
                  </Route>

                  <Route path={PATH_NAME.STORES}>
                    <Route
                      index
                      element={
                        <Permission
                          roles={[ROLE.ADMIN, ROLE.CUSTOMER]}
                          noAccess={<NotFound />}
                        >
                          <Stores />
                        </Permission>
                      }
                    />

                    <Route
                      path=":id"
                      element={
                        <Permission
                          roles={[ROLE.ADMIN, ROLE.CUSTOMER]}
                          noAccess={<NotFound />}
                        >
                          <ViewStore />
                        </Permission>
                      }
                    />
                  </Route>

                  <Route path={PATH_NAME.STORE_TYPES}>
                    <Route
                      index
                      element={
                        <Permission
                          roles={[ROLE.ADMIN]}
                          noAccess={<NotFound />}
                        >
                          <StoreTypes />
                        </Permission>
                      }
                    />
                  </Route>

                  <Route path={PATH_NAME.DASHBOARD}>
                    <Route
                      path={PATH_NAME.DASHBOARD_MAP}
                      element={
                        <Permission
                          roles={[ROLE.ADMIN, ROLE.CUSTOMER]}
                          noAccess={<NotFound />}
                        >
                          <ViewMap />
                        </Permission>
                      }
                    />
                  </Route>

                  <Route path="*" element={<NotFound />} />
                </Route>
              </Route>

              <Route element={<AuthLayout />}>
                <Route
                  path={PATH_NAME.REQUEST_PERMISSION_EXPORT}
                  element={<RequestPermissionExport />}
                />

                <Route path={PATH_NAME.LOGIN} element={<Login />} />
              </Route>

              <Route path="*" element={<NotFound />} />
            </Routes>
          </ThemeProvider>

          <ReactQueryDevtools />
        </QueryClientProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}

export default App;
