import { useMemo, useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import _ from 'lodash';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { useGetStoreMap } from 'api/dashboardApi';
import MapFilterSearch from '../MapFilterSearch/MapFilterSearch';
import MapBlock from '../MapBlock/MapBlock';
import MapInfo from '../MapInfo/MapInfo';

export default function Map() {
  const [search, setSearch] = useCustomSearchParams();
  const [infos, setInfos] = useState([]);
  const {
    data = [],
    isLoading,
    isFetching,
  } = useGetStoreMap({ params: search });

  console.log({ data });

  const markers = useMemo(() => {
    return _.reduce(
      data,
      (acc, currentItem) => {
        const { color, gps } = currentItem;

        acc.push(..._.map(gps, (item) => ({ ...item, color })));

        return acc;
      },
      [],
    );
  }, [data]);

  useEffect(() => {
    if (data.length <= 0) return;

    setInfos(_.map(data, ({ name, color, total }) => ({ name, color, total })));
  }, [data]);

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24} sm={8}>
          <MapFilterSearch
            onSubmit={(values) => setSearch(values)}
            initialValues={search}
          />

          <MapInfo infos={infos} isLoading={isFetching} />
        </Col>

        <Col span={24} sm={16}>
          <MapBlock isLoading={isLoading || isFetching} markers={markers} />
        </Col>
      </Row>
    </>
  );
}
