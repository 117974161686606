import { useQuery } from 'react-query';
import axiosClient from './axiosClient';

export const locationApi = {
  getProvinces(params) {
    return axiosClient.get('/provinces', { params });
  },
  getDistricts(provinceId, params) {
    return axiosClient.get(`/provinces/${provinceId}/districts`, { params });
  },
  getWards(provinceId, districtId, params) {
    return axiosClient.get(
      `/provinces/${provinceId}/districts/${districtId}/wards`,
      { params },
    );
  },
  getMarkets(params) {
    return axiosClient.get('/markets', { params });
  },
};

export const useProvinces = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['provinces', 'list', params],
    queryFn: () => locationApi.getProvinces(params),
    ...options,
  });
};

export const useDistricts = ({ provinceId, params, options } = {}) => {
  return useQuery({
    queryKey: ['provinces', provinceId, 'districts', 'list', params],
    queryFn: () => locationApi.getDistricts(provinceId, params),
    enabled: !!provinceId,
    ...options,
  });
};

export const useWards = ({ provinceId, districtId, params, options } = {}) => {
  return useQuery({
    queryKey: [
      'provinces',
      provinceId,
      'districts',
      districtId,
      'wards',
      'list',
      params,
    ],
    queryFn: () => locationApi.getWards(provinceId, districtId, params),
    enabled: !!provinceId && !!districtId,
    ...options,
  });
};

export const useMarkets = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['markets', 'list', params],
    queryFn: () => locationApi.getMarkets(params),
    ...options,
  });
};
