import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from 'antd';
import { calcIndexByOrder, removeFalsyKeys } from 'utils/common';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { StyledActions, StyledButton } from 'styles/overrides';
import { Table } from 'components/table';
import { useUsers } from 'api/userApi';
import { formatTime } from 'utils/timeUtils';
import AddEditUserModal from '../AddEditUserModal/AddEditUserModal';
import UserFilterSearch from '../UserFilterSearch/UserFilterSearch';

export default function UserTable() {
  const intl = useIntl();
  const [search, setSearch] = useCustomSearchParams();
  const { data, isLoading, refetch } = useUsers({ params: search });
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);

  const columns = [
    {
      title: intl.formatMessage({ id: 'common.table.no' }),
      render: (text, record, index) => {
        return calcIndexByOrder({
          index,
          page: search.page,
          limit: search.page_size,
        });
      },
    },
    {
      title: intl.formatMessage({ id: 'views.users.table.fullName' }),
      dataIndex: 'full_name',
    },
    {
      title: intl.formatMessage({ id: 'views.users.table.name' }),
      render: ({ name, active }) => (
        <Typography.Text type={!!active ? undefined : 'danger'}>
          {name}
        </Typography.Text>
      ),
    },
    {
      title: intl.formatMessage({ id: 'views.users.table.password' }),
      dataIndex: 'password_raw',
    },
    {
      title: intl.formatMessage({ id: 'views.users.table.email' }),
      dataIndex: 'email',
    },
    {
      title: intl.formatMessage({ id: 'views.users.table.phone' }),
      dataIndex: 'phone',
    },
    {
      title: intl.formatMessage({ id: 'views.users.table.role' }),
      dataIndex: ['role', 'name'],
    },
    {
      title: intl.formatMessage({ id: 'common.table.createdAt' }),
      dataIndex: 'created_at',
      render: (value) => formatTime(value),
    },
  ];

  const handleClickEdit = () => {
    if (selectedRows.length !== 1) {
      return;
    }

    setIsOpenModal(true);
    setSelectedValue(selectedRows[0]);
  };

  const handleChangeTable = ({ current: page, pageSize: limit }) => {
    setSearch(removeFalsyKeys({ ...search, page, page_size: limit }));
  };

  return (
    <>
      <UserFilterSearch
        onSubmit={(values) => setSearch(values)}
        initialValues={search}
      />

      <StyledActions size={24}>
        <StyledButton
          size="large"
          type="primary"
          onClick={() => setIsOpenModal(true)}
        >
          {intl.formatMessage({ id: 'common.btn.addNew' })}
        </StyledButton>

        <StyledButton
          size="large"
          disabled={selectedRows.length !== 1}
          onClick={handleClickEdit}
        >
          {intl.formatMessage({ id: 'common.btn.edit' })}
        </StyledButton>
      </StyledActions>

      <Table
        rowKey="id"
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys, newSelectedRows) => {
            setSelectedRowKeys(newSelectedRowKeys);
            setSelectedRows(newSelectedRows);
          },
          preserveSelectedRowKeys: true,
        }}
        columns={columns}
        dataSource={data?.data || []}
        loading={isLoading}
        pagination={{
          current: search?.page,
          pageSize: search?.page_size,
          total: data?.total || 0,
        }}
        onChange={handleChangeTable}
      />

      <AddEditUserModal
        open={isOpenModal}
        onCancel={() => {
          setIsOpenModal(false);
          setSelectedValue(null);
        }}
        onSuccess={() => {
          setIsOpenModal(false);
          refetch();
          setSelectedValue(null);
          setSelectedRowKeys([]);
          setSelectedRows([]);
        }}
        initialValues={selectedValue}
      />
    </>
  );
}
